import Head from "next/head";
import { merge } from "lodash";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import {
  lightTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import "styles/globals.css";
import "@rainbow-me/rainbowkit/styles.css";

const { chains, provider } = configureChains(
  [chain.mainnet],
  [alchemyProvider({ alchemyId: process.env.ALCHEMY_ID }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "Cuter",
  chains,
});

const rainbowKitTheme = merge(
  lightTheme({
    accentColor: "#0d9488",
    accentColorForeground: "#fff",
    borderRadius: "small",
  }),
  { fonts: { body: "Assistant" } }
);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

// Use of the <SessionProvider> is mandatory to allow components that call
// `useSession()` anywhere in your application to access the `session` object.
export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.png" />
      </Head>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} theme={rainbowKitTheme}>
          <SessionProvider session={pageProps.session} refetchInterval={0}>
            <Component {...pageProps} />
          </SessionProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}
